<template>
    <div id="mian" class="clear">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>商户名称</i>
            <el-select
              v-model="query.keyword"
              filterable
              clearable
              :filter-method="searchMchList"
              @visible-change="unfoldMchList"
              @clear="clearMchList"
              @change="changeMch"
              placeholder="商户名称/店铺名称"
              :no-data-text="MerchantShopData=='' ? '无数据':'可搜索'"
            >
              <el-option
                v-for="item in MerchantShopData"
                :key="item.mchId"
                :label="item.mchName + ' | ' + item.mchId"
                :value="item.mchId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
						<i>报备商户号</i>
						<el-input v-model="query.tradeMchNo" maxlength="128" suffix-icon="el-icon-search"
						placeholder="报备商户号"></el-input>
					</div>
          <div class="item">
            <i>产品类型</i> 
            <el-select
              v-model="query.productId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in productDrop"
                :key="i"
                :label="v.productName"
                :value="v.productId"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item">
            <i>风控状态</i>
            <el-select
              v-model="query.tradeStatus"
              placeholder="全部"
            > 
              <el-option label="全部" value=""></el-option>
              <el-option label="初始" value="1"></el-option>
              <el-option label="正常" value="2"></el-option>
              <el-option label="异常" value="0"></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>路由状态</i>
            <el-select
              v-model="query.routeStatus"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="未交易" value="0"></el-option>
              <el-option label="交易中" value="1"></el-option>
            </el-select>
          </div>
          <div class="item">
            <i>冻结状态</i>
            <el-select
              v-model="query.status"
              placeholder="全部"
            >  
              <el-option label="全部" value=""></el-option>
              <el-option label="已冻结" value="0"></el-option>
              <el-option label="未冻结" value="1"></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>创建时间</i>
            <el-date-picker
              v-model="query.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
          <div class="itembtn">
            <el-button @click="getLists" class="searchBt">查询</el-button>
            <el-button @click="addIntell" class="outputBt" v-auth="'ACCOUNT:INTETT:DEPLOY/ADD'">新增</el-button>
          </div>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>创建时间</p>
            </td>
            <td>
              <p>商户名称</p>
              <p>商户编号</p>
            </td>
            <td>
              <p>产品类型</p>
            </td>
            <td>
              <p>店铺名称</p>
              <p>店铺编号</p>
            </td>
            <td>
              <p>报备商户</p>
              <p>报备商户号</p>
            </td>
            <td>
              <p>风控状态</p>
            </td>
            <td>
              <p>路由状态</p>
            </td>
            <td>
              <p>冻结状态</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="`${i}s`">
            <td>
              <p>{{ v.createTime }}</p>
            </td>
            <td class="break">
              <p>{{ v.mchName }}</p>
              <p>{{ v.mchId }}</p>
            </td>
            <td>
              <p>{{ v.productName }}</p>
            </td>
            <td>
              <p>{{ v.storeName }}</p>
              <p>{{ v.storeId }}</p>
            </td>
            <td class="break">
              <p>{{ v.tradeMchName ? v.tradeMchName : "" }}</p>
              <p>{{ v.tradeMchNo ? v.tradeMchNo : "" }}</p>
            </td>
            <td>
              <p>{{ tradeStatusData[v.tradeStatus] }}</p>
            </td>
            <td>
              <p> {{ v.routeStatus == 1 ? '交易中' : '未交易' }}</p>
            </td>
            <td>
              <p>{{ v.status == 1 ? '未冻结' : '已冻结' }}</p>
            </td>
            <td>
              <p>
                <i class="lianjie" @click="resettingBtn(v,i)" v-auth="'ACCOUNT:INTETT:DEPLOY/RESET'" v-if="v.tradeStatus==0">重置状态</i>
                <i class="lianjie" @click="freezeBtn(v,i)"  v-auth="'ACCOUNT:INTETT:DEPLOY/FREEZE'">{{  v.status == 1 ? '冻结' : '解冻' }}</i>
              </p>
            </td>
          </tr>
        </table>
      </div>
      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>

      <el-dialog title="新增智能路由配置" :visible.sync="mchShow" width="490px">
        <ul class="overAccount_ul">
          <li class="ul_left">
            <div class="overAccount_ul_title"><i>*</i>配置商户</div>
            <el-select
              v-model="mchId"
              placeholder="选择配置商户"
              class="width100"
            >
              <el-option
                :label="v.mchName"
                :value="v.mchId"
                v-for="v in mchDrop"
                :key="v.mchId"
              ></el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="addBt1" @click="addclickHander">确 定</el-button>
        </span>
      </el-dialog>
    </div>

</template>
<script>
import { formatDate } from "@/utils/common.js";
import { getRouteconfigs , intellResettingStatus , intellFreezeStatus } from "@/api/user/shopManager.js";
import {MerchantShopDrop , merchantsDrop , payproductsDrop} from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      MerchantShopData: [], //分账商户名称/编号可选项
      keywordData:{mchId:"",mchName:"",},
      productDrop: [], //产品类型数据
      mchDrop:[],
      mchShow: false,
      mchId: "",
      query: {
        // startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        // endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        startTime: "",
        endTime: "",
        pageNo: 1,
        pageSize: 10,
        keyword: "",// 商户名称/店铺名称
        productId:"",// 产品id
        status:"",// 冻结状态 1未冻结，0冻结
        tradeStatus:"",// 风控状态  ：2正常，1初始，0异常，默认初始
        routeStatus:"",// 路由状态 ：0未交易1交易中
        tradeMchNo:"",// 报备商户号
      },
      tradeStatusData:{
        0:"异常",
        1:"初始",
        2:"正常",
      },
      totalNum: 0, //数据总条数
      tabData: [],
    };
  },
  computed: {
    ...mapState({
      intelligence: "user_intelligenceList",
    }),
  },
  created() {
    if (this.intelligence) {
      this.query = this.intelligence.query;
      this.keywordData = this.intelligence.keywordData;
    }
    if(JSON.stringify(this.keywordData) != '{}' && this.keywordData != undefined && this.keywordData.mchId != ""){
      this.query.keyword = this.keywordData.mchName + ' | ' + this.keywordData.mchId;
    }
    this.getDrop();
    this.getLists(false);
  },
  methods: {
    ...mapMutations({
      setIntelligence: "user_setIntelligenceList",
    }),

    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      let query = { ...this.query };
      if(this.keywordData != undefined && this.keywordData.mchId != ""){
        query.keyword = this.keywordData.mchId;
      }
      getRouteconfigs(query).then((res) => {
        if (res.resultStatus) {
          this.tabData = res.resultData.mchRouteList;
          this.totalNum = res.resultData.totalNum;
          console.log("表格数据", this.tabData);
        }
      });
    },
    addIntell(){
      this.mchShow = true;
    },
    addclickHander() {
      if(!this.mchId){
        this.$message.error("请选择配置商户");
        return;
      }
      this.mchShow = false;
      let obj = this.mchDrop.find((obj) => obj.mchId == this.mchId);
      this.$router.push({
        name: "/user/intelligenceAdd",
        query: {
          mchId: this.mchId,
          mchName: obj.mchName,
        },
      });
    },
    //重置
    resettingBtn(v,i){
      this.$confirm("确定重置状态", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        intellResettingStatus(v.mchId,v.id,v).then((res) => {
          if (res.resultStatus) {
            this.$message({type: "success",message: "已成功重置状态",});
            this.getLists();
          }
        });
      }).catch(() => {
          this.$message({type: "info",message: "已取消重置状态",});
      }); 
    },
    //冻结
    freezeBtn(v,i){
      let title =  v.status == 1 ? '冻结' : '解冻'
      console.log( v.status , " v.status")
      this.$confirm("确定"+title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let data = {
          status:v.status==1 ? 0 : 1,
        }
        intellFreezeStatus(v.mchId,v.id,data).then((res) => {
          if (res.resultStatus) {
            this.$message({type: "success",message: "已成功"+title,});
            this.getLists();
          }
        });
      }).catch(() => {
          this.$message({type: "info",message: "已取消"+title,});
      }); 
    },
    // 产品类型数据
    getDrop() {
      payproductsDrop().then((res) => {
        if (res.resultStatus) {
           this.productDrop = res.resultData;
        }
      });
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 获取商户下拉列表
    _searchAllMch(value) {
      this.MerchantShopData = [];
      let params = {
        keyword: value,
      };
      MerchantShopDrop(params).then((res) => {
        if (res.resultStatus) {
          this.MerchantShopData = res.resultData;
          console.log(this.MerchantShopData);
        }
      });
    },
    // 商户下拉列表输入搜索内容时
    searchMchList(val) {
      if (val) {
        let value = val;
        this._searchAllMch(value);
      } else {
        // 搜索内容为空时还原列表
        this._searchAllMch();
      }
    },
    // 商户下拉框展开/收起时触发
    unfoldMchList(e) {
      if (e) {
        //  展开时
        this._searchAllMch();
      } else {
        //  折叠时
        this.MerchantShopData = [];
      }
    },
    // 商户下拉搜索框清空搜索内容时
    clearMchList() {
      this.query.keyword = ""; //清空选中的商户id
      this.keywordData = {};
      this._searchAllMch(); //重新获取所有商户下拉列表
    },
    changeMch(e) {
      this.query.keyword = e;
      let mchId  = this.query.keyword;
      console.log( this.query.keyword );
      let result = this.MerchantShopData.filter(e=>e.mchId === mchId)
      if(result && result.length > 0){
        console.log(result[0])
        this.keywordData = result[0];
      }
    }, 
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
      keywordData: this.keywordData,
    };
    this.setIntelligence(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime{
  width: 58.238%;
}
.con_from .lineP .item i {
  width: 76px;
}

.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 76px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 130px - 76px - 10px) / 2);
}
.con_from .lineP .item .inputW {
  width: calc((100% - 76px - 24.67px - 10px) / 2);
}
.con_from .lineP .item .inputW ::v-deep .el-input__inner{
  padding: 0px 0px 0px 15px !important;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}
.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}
.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .ul_left {
  width: 200px;
  margin: 0 auto;
}

.addBt1 {
  width: 60px;
  height: 32px;
  background-color: #48b8b6 !important;
  border-color: #66b2af !important;
  color: #ffffff !important;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul_title i {
  color: #48b8b6;
  padding-right: 3px;
}
/deep/.el-dialog__footer {
  overflow: hidden;
}
</style>
